<template>
    <section class="accueil">
        <div>
            <img class="w-100" src="@/assets/images/import/cpts/couverture.png" alt="">
        </div>
        <div class="container">
            <div class="row my-5">
                <div class="d-flex justify-content-around">
                    <h1 class="extra">Ensemble, dynamisons la santé sur notre territoire</h1>
                    <img style="width:100%;height:auto" src="@/assets/images/import/cpts/logo-mini.png" alt="">
                </div>
            </div>
            <div class="row text-center my-5" style="margin-top:6rem;">
                <h2 class="text-normal">La Communauté professionnelle Territoriale de la Santé (CPTS) de **** <br>
                <span class="text-lighter">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam possimus quisquam, magnam odit veniam sequi saepe maiores consectetur.</span> <br>
                <br>
                Descriptif <br>
                <span class="text-lighter">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores vel voluptatem qui maxime animi odit eum excepturi. </span> 
                </h2>
            </div>
        </div>
        <hr>
        <!-- A propos -->
        <div class="container">
            <div class="d-flex card-type-4">
                <div class="card-invi d-flex flex-column justify-content-between p-4 text-center">
                    <h3>Nos actions</h3>
                    <p>Notre CPTS a mis en place différentes actions <br>
                        afin d'aider le plus possible <br>
                        A COMPLETER
                    </p>
                    <button class="btn btn-primary btn-rounded">Voir toutes les actions</button>
                </div>
                <span class="border-right"></span>
                <div class="card-invi d-flex flex-column justify-content-between p-4 text-center">
                    <h3>Recherche médecin traitant</h3>
                    <p>Vous arrivez sur notre territoire ? 
                    Votre médecin traitant stoppe son activité ? <br>

                    Aucun soucis, la CPTS a mis un dispositif
                    pour vous ! <br>

                    En seulement 2 minutes via un formulaire, vous <br>
                    pouvez effectuer votre demande afin d'avoir 
                    un médecin traitant. <br>

                    C'est par ici ! 
                    </p>
                    <button class="btn btn-primary btn-rounded">Voir toutes les actions</button>
                </div>
                <span class="border-right"></span>
                <div class="card-invi d-flex flex-column justify-content-between p-4 text-center">
                    <h3>Fonctionnement CPTS</h3>
                    <p>Vous ne savez pas ce qu'est une CPTS ?
                    Le sujet reste flou ? <br>

                    Par ici, on t'explique son <br>
                    fonctionnement ainsi que sa création
                    </p>
                    <button class="btn btn-primary btn-rounded">Voir toutes les actions</button>
                </div>
            </div>
        </div>
        <hr>
        <!-- CPTS Chiffre -->
        <div class="cpts-numbers mb-5">
            <div class="container">
                <h3 class="text-center">La CPTS en chiffre</h3>
                <div class="d-flex justify-content-around">
                    <div class="card w-100 m-2">
                        <div class="card-body text-center">
                            <h1 class="normal" style="color:#8c1078">43</h1>
                            <h3>Commune</h3>
                        </div>
                    </div>
                    <div class="card w-100 m-2">
                        <div class="card-body text-center">
                            <h1 class="normal" style="color:#fc2b5d">330</h1>
                            <h3>Professionels de Santé</h3>
                        </div>
                    </div>
                    <div class="card w-100 m-2">
                        <div class="card-body text-center">
                            <h1 class="normal" style="color:#f9c728">71455</h1>
                            <h3>Habitants</h3>
                        </div>
                    </div>
                </div>
                <div class="external-card d-flex justify-content-center mt-5">
                    <div class="card">
                        <div class="card-body">
                           <div class="card-body text-center">
                                <h1 class="normal">205</h1>
                                <h3>Adhérents</h3>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container text-center my-5">
            <div class="row">
                <h2 class="text-lighter">Le projet de santé de la CPTS de **************** vise à améliorer la prise en charge de la santé de la population du territoire en renforçant la coordination interprofessionnelle, et en développant les échanges entre les professionnels de santé libéraux et les acteurs des secteurs médico-social et social.</h2>    
            </div>
        </div>

        <!-- card-type-2/ -->
        <div class="container">
            <div class="d-flex justify-content-around card-type-2">
                <div class="card card-rounded-1 m-4">
                    <div class="card-body d-flex flex-column justify-content-center text-center">
                        <i class="fa fa-users"></i>
                        <p class="mt-4">Association loi 1901 créée en xxxx/xxxx/xxxx </p>
                    </div>
                </div>
                <div class="card card-rounded-2 m-4">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                        <img src="@/assets/images/import/cpts/heart.png" alt="">
                        <p class="mt-4">Groupements de  professionnels de santé et d'acteurs des secteurs médico-social et social</p>
                    </div>
                </div>
                <div class="card card-rounded-1 m-4">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                        <img src="@/assets/images/import/cpts/chat.png" alt="">
                        <p class="mt-4">Nous répondons au besoins de santé du territoire</p>
                    </div>
                </div>
                <div class="card card-rounded-2 m-4">
                    <div class="card-body d-flex flex-column justify-content-center align-items-center text-center">
                        <img src="@/assets/images/import/cpts/give.png" alt="">
                        <p class="mt-4">Financée par l'Assurance Maladie et l'Agence Régionale de Santé</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- .card-type-3 -->
        <div class="sign-in position-relative card-type-3">
            <div class="container d-flex justify-content-around">
                <div class="card">
                    <div class="card-header">
                        Je suis adhérent
                    </div>
                    <div class="card-body text-center">
                        <p>J'ai accès à des infos et documents liés à la vie associative, au territoire, au réseau...</p>
                        <button class="btn btn-primary btn-rounded">Se connecter</button>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        Pas encore adhérent? 
                    </div>
                    <div class="card-body text-center">
                        <p>Vous êtes praticien et vous voulez être enregistré dans notre annuaire ?</p>
                        <button class="btn btn-primary btn-rounded">Je veux adhérer</button>
                    </div>
                </div>
            </div>
            <img class="sign-in-img" src="@/assets/images/import/cpts/arc.png" alt="">
        </div>

        <!-- A propos--2  -->
        <div class="position-relative" style="background:white;z-index:20">
            <div class="container">
                <div class="d-flex card-type-4">
                    <div class="card-invi d-flex flex-column justify-content-around p-4 text-center">
                        <h3>N° d'urgence</h3>
                        <p>Il est important de connaître les différents
                            numéros d'urgence pour être réactif en cas
                            de problème majeures. <br>

                            Vous souhaitez connaître un 
                            numéro d'urgence ?
                        </p>
                        <button class="btn btn-primary btn-rounded">N° d'urgence</button>
                    </div>
                    <span class="border-right"></span>
                    <div class="card-invi d-flex flex-column justify-content-around p-4 text-center">
                        <h3>Territoire</h3>
                        <p>Vous souhaitez savoir dans quel lieu la CPTS agit ? 
                                Nous avons effectué une map reprenant tous les 
                                endroits ou la CPTS est présente.
                        </p>
                        <button class="btn btn-primary btn-rounded">Voir la map</button>
                    </div>
                    <span class="border-right"></span>
                    <div class="card-invi d-flex flex-column justify-content-around p-4 text-center">
                        <h3>Nos actualités</h3>
                        <p>Retrouvez l'ensemble de notre actualité de notre CPTS
                        </p>
                        <button class="btn btn-primary btn-rounded">Actualités</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- horizontal banner -->
        <div class="horizontal-banner d-flex align-items-center">
            <div class="container">
                <div class="d-flex flex-column justify-content-center align-items-center text-center">
                    <h3>Besoin d'un renseignement?</h3>
                    <button class="btn btn-primary btn-rounded">Nous contacter</button>
                </div>
            </div>
        </div>

    </section>
</template>
<script>
export default {
    name:"accueil-2"
}
</script>
<style scoped>
.accueil .border-right {
    border-right:2px solid #23b2c2 !important
}
.cpts-numbers {
    position: relative;
    height: auto;
}
.cpts-numbers:before{
    content: " ";
    height: 80%;
    width: 100%;
    background-color: #8c1078;
    position: absolute;
}
.cpts-numbers .card{
    border-radius:1rem
}
.external-card .card {
    color: #fff;
    background-color: #8c1078;
    border: 4px solid white;
}
/* card-type-2 */
.card-type-2 .fa, .card-type-2 .mdi {
    color: #000;
    font-size: 4rem;
}
.card-type-2 .card {
    width: 100%;
}
.card-type-2 img {
    width: 5rem;
}
.card-rounded-1 {
    border-radius: 20px;
    background: #8c1078;
    color: white;
}
.card-rounded-2 {
    border: 2px solid #8c1078;
    border-radius: 20px;
    background: none;
    color: #8c1078;
}

/* card-type-3 */
.card-type-3 {
    background: rgb(226, 226, 226);
    border-top: 1px solid rgb(152, 152, 152);
    border-bottom: 1px solid rgb(152, 152, 152)
}
.card-type-3 .card {
    width: 100%;
    margin: 4rem;
}
/* card-type-4 */
.sign-in-img {
   position: absolute;
    width: 27vw;
    right: -67px;
    top: -91px;
    z-index: 2;
}
.card-type-4 .card-invi {
    margin: 2rem;
    width:100%;
}
/* horizonta-banner */
.horizontal-banner {
    min-height: 40vh;
    background: #8c1078;
}
.horizontal-banner h3 {
    color: #fff;
}
</style>